import {
  Alert,
  Divider,
  ListItem,
  ListItemText,
  MenuItem,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const SingleCommunicationComponent = ({
  type = "info",
  text = "",
  title = "",
  newId = "",
  onClick = () => {},
}) => {
  const lines = text.split("\n");

  return (
    <div>
      <Divider />
      <ListItem style={{ whiteSpace: "normal" }} onClick={onClick}>
        <ListItemText>
          <Alert title={title} severity={type}>
            {/* Map over each line and render it */}
            {lines.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {/* Add a <br /> element after each line, except for the last one */}
                {index !== lines.length - 1 && <br />}
              </React.Fragment>
            ))}
          </Alert>
        </ListItemText>
      </ListItem>
    </div>
  );
};

export default SingleCommunicationComponent;

SingleCommunicationComponent.propTypes = {
  type: PropTypes.oneOf(["error", "warning", "info"]),
  text: PropTypes.string,
  title: PropTypes.string,
  newId: PropTypes.string,
  onClick: PropTypes.func,
};
