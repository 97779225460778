import { LicenseInfo } from "@mui/x-license";
LicenseInfo.setLicenseKey(
  "b3ad11d17a17e9ab759924e74fe1b11dTz04NTU4OSxFPTE3NDEwMDYyOTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

import React from "react";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const RootComponent = () => {
  const isLocalInstallation =
    process.env.REACT_APP_LOCAL_INSTALLATION === "YES";

  if (isLocalInstallation) {
    return <App />;
  } else {
    return (
      <Auth0Provider
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        }}
        scope="read:current_user profile email"
      >
        <App />
      </Auth0Provider>
    );
  }
};

root.render(<RootComponent />);

//root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
