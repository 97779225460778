const initialState = {
  local_token: null,
};

const LocalTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOCAL_TOKEN":
      return { ...state, local_token: action.payload };
    default:
      return state;
  }
};

export default LocalTokenReducer;
