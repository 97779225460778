/* eslint-disable react/prop-types */
import React, { useState } from "react";

import { useSelector } from "react-redux";
import LoginPage from "../pages/LoginPage";

import MainLayout from "./components/MainLayout";

const Local_Layout = () => {
  const [open, setOpen] = useState(true);

  const { local_token } = useSelector((state) => {
    return {
      local_token: state.token.local_token,
    };
  });

  const { local_user } = useSelector((state) => {
    return {
      local_user: state.auth.user,
    };
  });

  console.log(
    "local token ",
    local_token,
    local_user,
    process.env.REACT_APP_LOCAL_INSTALLATION
  );

  const handleOpen = (value) => {
    setOpen(value);
    //save property
    //api_remote.save_user_configuration({
    //config: { left_bar_open: value },
    //});
  };

  const localBuild = (token, user) => {
    return token && user ? (
      <MainLayout user={user} setOpen={handleOpen} open={open} />
    ) : (
      <div className="main">
        <LoginPage />
      </div>
    );
  };

  return localBuild(local_token, local_user);
};

export default Local_Layout;
