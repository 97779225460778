import { Navigate, Route } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import React from "react";

const useLayoutFunctions = () => {
  const theme = useTheme();
  const DRAWER_WIDTH_OPEN = 260;
  const DRAWER_WIDTH_CLOSE = 70;

  const useStyles = {
    sidebar: {
      width: 300,
      background: theme.palette.primary.main,
      height: "100%",
      position: "fixed",
    },
    avatar: {
      margin: "0.5rem auto",
      padding: "1rem",
      width: theme.spacing(13),
      height: theme.spacing(13),
    },
    label: {
      color: "white",
      textAlign: "left",
    },
    logo: {
      display: "block",
      maxWidth: "80%",
      maxHeight: "100%",
      margin: "auto",
      marginTop: "30px",
    },
    link: {
      textDecoration: "none",
      color: "white",
      textColor: "white",
    },
  };

  const correctRouter = (selectedUser) => {
    if (selectedUser.permission_list.includes("TEST_LIST")) {
      return <Route path="/" element={<Navigate to="testlist" replace />} />;
    } else if (selectedUser.permission_list.includes("PATIENT_LIST")) {
      return <Route path="/" element={<Navigate to="patientlist" replace />} />;
    } else if (selectedUser.permission_list.includes("SF_PATIENT_LIST")) {
      return (
        <Route path="/" element={<Navigate to="sfpatientlist" replace />} />
      );
    } else if (selectedUser.permission_list.includes("VARIANTS_CS")) {
      return <Route path="/" element={<Navigate to="variantscs" replace />} />;
    } else if (selectedUser.permission_list.includes("TRACKING")) {
      return <Route path="/" element={<Navigate to="tracking" replace />} />;
    } else if (selectedUser.permission_list.includes("NEW_TEST_FORM")) {
      return (
        <Route path="/" element={<Navigate to="register_fill" replace />} />
      );
    } else if (selectedUser.permission_list.includes("NEW_TEST_CSV")) {
      return (
        <Route path="/" element={<Navigate to="register_csv" replace />} />
      );
    } else if (selectedUser.permission_list.includes("GENETIC_IDAT")) {
      return <Route path="/" element={<Navigate to="loadIdat" replace />} />;
    } else if (selectedUser.permission_list.includes("GENETIC_VCF")) {
      return <Route path="/" element={<Navigate to="loadVcf" replace />} />;
    } else if (selectedUser.permission_list.includes("GENETIC_FASTQ")) {
      return <Route path="/" element={<Navigate to="loadFastq" replace />} />;
    } else if (selectedUser.permission_list.includes("READ_PROTEIN_DRUG")) {
      return <Route path="/" element={<Navigate to="PRISMList" replace />} />;
    }
  };

  return {
    DRAWER_WIDTH_OPEN,
    DRAWER_WIDTH_CLOSE,
    correctRouter,
    useStyles,
  };
};

export default useLayoutFunctions;
