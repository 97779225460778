import {
  Avatar,
  Badge,
  ClickAwayListener,
  Divider,
  Grow,
  List,
  ListItem,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import React, { useRef, useState } from "react";
import useApi from "../../api/useApi";
import PropTypes from "prop-types";
import SingleCommunicationComponent from "./SingleCommunicationComponent";

const NewsComponent = (props) => {
  const { isLoading } = useApi();

  const anchorRefNews = useRef();

  const [openNewsPopup, setOpenNewsPopup] = useState(false);

  const handleCloseNewsList = () => {
    setOpenNewsPopup(false);
  };

  const handleOpenNewsList = () => {
    setOpenNewsPopup(true);
  };

  const handleOnClickNews = (event) => {
    console.log("You click on the news ", event);
  };

  return (
    <div>
      {/*<Badge
        badgeContent={4}
        color="primary"
        style={{ marginRight: 20 }}
        onClick={() => {
          setOpenNewsPopup((prev) => !prev);
        }}
        ref={anchorRefNews}
      >*/}
      <Avatar color="action" sx={{ color: "#FFFFFF", marginRight: 2 }} />
      {/*</Badge>*/}
      <Popper
        sx={{
          zIndex: 100,
        }}
        open={openNewsPopup}
        anchorEl={anchorRefNews.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper style={{ backgroundColor: "#FFFFFF" }}>
              <ClickAwayListener onClickAway={handleCloseNewsList}>
                <List autoFocusItem style={{ width: 300 }}>
                  <SingleCommunicationComponent
                    title={"New feature"}
                    text={"Here we will display messages for you."}
                    newId={"Ab1238"}
                  />
                  <SingleCommunicationComponent
                    title={"Warning"}
                    text={"Sometimes these will be warnings"}
                    type={"warning"}
                    newId={"Ab1237"}
                  />
                  <SingleCommunicationComponent
                    title={"Attention"}
                    text={"And sometimes something that needs your attention"}
                    type={"error"}
                    newId={"Ab1236"}
                  />
                  <SingleCommunicationComponent
                    title={"Info"}
                    newId={"Ab1235"}
                    text={
                      "Sometimes just basic info. Click on the message to make it disappear."
                    }
                    type={"info"}
                    onClick={handleOnClickNews}
                  />
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default NewsComponent;
