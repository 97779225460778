import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../store/Actions/localTokenAction";
import * as ActionAuth from "../store/Actions/authAction";

const LoginPage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleLogin = async () => {
    setErrorMessage(false);
    setIsLoading(true);
    // Perform login logic here (send email and password to the server)
    // Example: You can use fetch to send a request to your server
    try {
      const response = await fetch("/api/authUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      console.log(data);
      // Assuming your server returns a token
      const token = data.token;

      //save in storage
      if (token) {
        dispatch(Actions.setLocalToken(token));

        const getUserInfoResponse = await fetch("/api/getUserInfo", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const userInfo = await getUserInfoResponse.json();
        dispatch(ActionAuth.setAuthenticatedUserData(userInfo));
        console.log("user dispatch done ", userInfo);
        console.log("token dispatch done");

        setIsLoading(false);
      } else {
        dispatch(Actions.setLocalToken(null));
        dispatch(ActionAuth.setAuthenticatedUserData(null));
        setIsLoading(false);
        setErrorMessage(true);
      }
      // Redirect or perform other actions upon successful login
    } catch (error) {
      console.error("Login failed:", error.message);
      setIsLoading(false);
      setErrorMessage(true);
      // Handle login failure
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{ backgroundColor: "white", height: "100vh" }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Paper elevation={3} style={{ padding: "2em", textAlign: "center" }}>
          <img
            src={"logo.png"}
            alt="Company Logo"
            style={{ width: "100%", marginBottom: "1em" }}
          />
          <Typography variant="h5">APS LOGIN</Typography>
          {errorMessage ? (
            <Alert severity="error">Login failed.</Alert>
          ) : (
            <div />
          )}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            disabled={isLoading}
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            disabled={isLoading}
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleLogin();
              }
            }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin}
            style={{ marginTop: "1em" }}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Login"
            )}
          </Button>
        </Paper>
      </Grid>
    </Container>
  );
};

export default LoginPage;
