/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import useApi from "../api/useApi";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../store/Actions/authAction";
import MainLayout from "./components/MainLayout";

const Remote_Layout = () => {
  const dispatch = useDispatch();
  const [remote_user, setUser] = useState({});
  const api_remote = useApi();

  const [open, setOpen] = useState(true);

  const { local_token } = useSelector((state) => {
    return {
      local_token: state.token.local_token,
    };
  });

  useEffect(() => {
    api_remote.get_user_info().then((res) => {
      setUser(res);
      dispatch(Actions.setAuthenticatedUserData(res));
      console.log(res.user_config, res.user_config.left_bar_open);
      if (res.user_config && res.user_config.left_bar_open != null) {
        console.log("modifying bar open with ", res.user_config.left_bar_open);
        setOpen(res.user_config.left_bar_open);
      }
    });
  }, []);

  /*
  useEffect(() => {
    api_remote.get_user_info().then((res) => {
      setUser(res);
      dispatch(Actions.setAuthenticatedUserData(res));
      console.log();
      if (res.user_config && res.user_config.left_bar_open) {
        setOpen(res.user_config.left_bar_open);
      }
    });
  }, [user]);
*/

  const handleOpen = (value) => {
    setOpen(value);
    //save property
    api_remote.save_user_configuration({ config: { left_bar_open: value } });
  };

  const remoteBuild = (user) => {
    return <MainLayout user={user} setOpen={handleOpen} open={open} />;
  };

  return remoteBuild(remote_user);
};

export default Remote_Layout;
