import Local_Layout from "./Local_Layout";
import Remote_Layout from "./Remote_Layout";
import React from "react";

const Layout = () => {
  return process.env.REACT_APP_LOCAL_INSTALLATION === "YES" ? (
    <Local_Layout />
  ) : (
    <Remote_Layout />
  );
};

export default Layout;
