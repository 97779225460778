/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Alert,
  CircularProgress,
  DialogContentText,
} from "@mui/material";
import { useSelector } from "react-redux";
import useApi from "../../api/useApi";

const ChangePasswordDialog = (props) => {
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [openOKPopup, setOpenOKPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { change_user_password, isLoading } = useApi();

  const { user } = useSelector((state) => {
    return {
      user: state.auth.user,
    };
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (value.length > 0) {
      if (name === "newPassword") {
        //check if it's equal to confirm password
        if (value !== formData.confirmPassword) {
          setPasswordMatch(false);
        } else {
          setPasswordMatch(true);
        }
      }
      if (name === "confirmPassword") {
        // check if it's equal to password
        if (value !== formData.newPassword) {
          setPasswordMatch(false);
        } else {
          setPasswordMatch(true);
        }
      }
    } else {
      setPasswordMatch(false);
    }
  };

  const handleSubmit = () => {
    const { username, oldPassword, newPassword, confirmPassword } = formData;

    change_user_password({
      old_password: oldPassword,
      password: newPassword,
    }).then((data) => {
      console.log(data);
      if (data && data.success && data.success === true) {
        setOpenOKPopup(true);
        setErrorMessage("");
        setTimeout(() => {
          setOpenOKPopup(false);
          //then reload info
        }, 3000);
        // Close the dialog after submitting
        props.onClose();
      } else {
        setOpenErrorPopup(true);
        setErrorMessage(data.error);
        //setErrorPopup(data.error);
        setTimeout(() => {
          setOpenErrorPopup(false);
        }, 3000);
      }
    });
  };

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={true}
                label="Username"
                name="username"
                value={user.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                label="Old Password"
                name="oldPassword"
                value={formData.oldPassword}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {passwordMatch ? (
              <Grid item xs={12}>
                <Alert severity="success">Password match!</Alert>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Alert severity="error">Password do not match!</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                label="New Password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                label="Confirm New Password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={
              !passwordMatch || formData.newPassword.length === 0 || isLoading
            }
          >
            {isLoading ? <CircularProgress /> : "Change Password"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openOKPopup}>
        <DialogTitle>
          <Alert severity="success">Success</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Password changed correctely</DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog open={openErrorPopup}>
        <DialogTitle>
          <Alert severity="error">Error</Alert>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            An error has occurred while changing the password. Please retry.
            {errorMessage}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChangePasswordDialog;
